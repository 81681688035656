<template>
  <dog-dialog
    ref="dogDialog"
    width="800px"
    title="设置轮播图"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      :column-num="2"
      :col-rule="(row) => row.col || 12"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import companyService from '@/api/service/company.js';
  import batchImgUpload from '@/components/batchImgUpload.vue';

  export default {
    name: 'setBannerDialog',
    data() {
      return {
        companyId: null,
        formData: {},
        formOptions: [
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '轮播图',
              rules: [this.$formRules.required('请上传轮播图')]
            },
            component: batchImgUpload,
            col: 24
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.companyId = id;
        this.$refs.dogDialog.open();
        companyService.getBanner({ companyId: id }).then((res) => {
          this.formData.img = res;
        });
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            if (this.formData.img.length > 10) {
              this.$message({
                type: 'error',
                message: '最多只能添加 10张 轮播图！'
              });
              return Promise.reject();
            }
            return companyService.setBanner({
              ...this.formData,
              companyId: this.companyId
            });
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.companyId = null;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
